import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaStar, FaShare, FaMinus, FaPlus, FaSpinner, FaClock } from 'react-icons/fa';
import { BaseUrl } from '../BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IoMdArrowBack } from "react-icons/io";
const TourDetail = () => {
    const navigate = useNavigate()
    const currentDate = new Date();

    const formattedDate = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    const location = useLocation();
    const { selectedPackage } = location.state || {};
    const [loading, setLoading] = useState(false);
    const { title, rating, days, nights, hours, price, image, description, date } = selectedPackage || {};
    console.log('selectedPackage', selectedPackage)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        adults: '',
        children: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        setLoading(true);

        const bookingData = {
            ...formData,
            title,
            days, nights, hours,
            price,
            date,
            image,
            description
        };
        try {
            // const response = await axios.post(`${BaseUrl}/api/bookings`, bookingData);
            // toast.success('Booking created successfully! Check your email for the booking code.');
            navigate('/payment', {
                state: {
                    bookingData
                }
            });
        } catch (error) {
            console.error(error);
            toast.error('Error creating booking. Please try again later.');
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="container mx-auto p-4 bg-white shadow-lg rounded-lg mt-28">
            <div className='text-[40px] cursor-pointer' onClick={() => navigate('/')}>
                <IoMdArrowBack />
            </div>
            <div className="flex flex-col md:flex-row pt-10">
                <div className="md:w-2/3 pr-4">
                    <h1 className="text-3xl font-bold mb-2">{title}</h1>

                    <div className="mb-4">
                        <p>{formattedDate}</p>
                    </div>
                    <h2>Durations</h2>
                    <div className="flex gap-3 items-center">
                        <div className="flex justify-start gap-x-2 items-center text-sm font-semibold text-gray-600 mb-2">
                            <FaClock />
                            <span> {days} Days</span>
                        </div>
                        <div className="flex justify-start gap-x-2 items-center text-sm font-semibold text-gray-600 mb-2">
                            <FaClock />
                            <span> {nights} Nights </span>
                        </div>
                        <div className="flex justify-start gap-x-2 items-center text-sm font-semibold text-gray-600 mb-2">
                            <FaClock />
                            <span> {hours} Hours </span>
                        </div>
                    </div>
                    <div className="mb-4">
                        <img src={image} alt={title} className="rounded-lg w-full" />
                    </div>
                    <div className='p-2'>
                        <p>{description}</p>
                    </div>
                </div>

                <div className="md:w-1/3 flex items-center justify-center">
                    <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-8">
                        <h2 className="text-2xl font-bold mb-6 text-center">Booking Form</h2>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <label className="block text-gray-700 font-semibold mb-2">Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                                    placeholder="Enter your name"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-semibold mb-2">Email</label>
                                <input
                                    type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                                    placeholder="Enter your Email"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-semibold mb-2">Children</label>
                                <input
                                    type="number"
                                    name="children"
                                    value={formData.children}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                                    placeholder="Number of children"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-semibold mb-2">Adults</label>
                                <input
                                    type="number"
                                    name="adults"
                                    value={formData.adults}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                                    placeholder="Number of adults"
                                    required
                                />
                            </div>
                            <button
                                disabled={loading}
                                type="submit"
                                className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-all duration-300"
                            >
                                {loading ? (
                                    <div className="flex items-center justify-center">
                                        <FaSpinner className="animate-spin mr-2" />
                                        Processing...
                                    </div>
                                ) : (
                                    'Book now'
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TourDetail;