import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import john from '../../assets/kkraimg/angela.png'
const testimonials = [
    {
        name: 'Isabel Rose',
        feedback: '”Sarah, a local resident, possesses a profound connection to this region. Having once served as a host, she intimately comprehends the significance of having someone dedicated to managing every intricate detail, ensuring a seamless experience for all.”',
        company: 'Manager',
        image: john,
        rating: 4,
    },
    {
        name: 'Oliver Mitchell',
        feedback: '”Liam, born and raised in this locale, has an innate connection to the area. With a background as a host himself, he fully appreciates the necessity of having someone who can attend to all the small but essential aspects, making every moment memorable.”',
        company: 'Landscape Architect',
        image: john,
        rating: 5,
    },
    {
        name: 'Michael Johnson',
        feedback: '”Sarah, a local resident, possesses a profound connection to this region. Having once served as a host, she intimately comprehends the significance of having someone dedicated to managing every intricate detail, ensuring a seamless experience for all.”',
        company: 'DEF Ltd',
        image: john,
        rating: 4,
    },
    {
        name: 'Michael Johnson',
        feedback: '”Sarah, a local resident, possesses a profound connection to this region. Having once served as a host, she intimately comprehends the significance of having someone dedicated to managing every intricate detail, ensuring a seamless experience for all.”',
        company: 'DEF Ltd',
        image: john,
        rating: 4,
    },
    {
        name: 'Michael Johnson',
        feedback: '”Sarah, a local resident, possesses a profound connection to this region. Having once served as a host, she intimately comprehends the significance of having someone dedicated to managing every intricate detail, ensuring a seamless experience for all.”',
        company: 'DEF Ltd',
        image: john,
        rating: 4,
    },
    {
        name: 'Michael Johnson',
        feedback: '”Sarah, a local resident, possesses a profound connection to this region. Having once served as a host, she intimately comprehends the significance of having someone dedicated to managing every intricate detail, ensuring a seamless experience for all.”',
        company: 'DEF Ltd',
        image: john,
        rating: 4,
    },
];

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            },
        },
    ],
};


const renderRating = (rating) => {
    return (
        <div className="flex justify-center items-center">
            {Array.from({ length: 5 }, (_, index) => (
                <span key={index} className={index < rating ? 'text-yellow-500 text-[40px]' : 'text-gray-300 text-[40px]'}>
                    ★
                </span>
            ))}
            <span className="text-sm text-gray-700 pl-2">{rating}/5</span>
        </div>
    );
};


const ReviewSection = () => {
    return (
        <div className="py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto text-center">
                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                    What Our Clients Say
                </h2>
                <p className="mt-4 text-lg text-gray-600">
                    Hear from our satisfied customers
                </p>
            </div>

            <div className="mt-10 max-w-6xl mx-auto">
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index}>
                            <div className="p-6 text-center mx-4">
                                <img
                                    className="w-24 h-24 border-2 border-[#17C3B3] shadow-lg rounded-full mx-auto mb-4"
                                    src={testimonial.image}
                                    alt={`${testimonial.name} profile`}
                                />
                                <p className="mt-4 text-gray-600 pt-6">{testimonial.feedback}</p>
                                <div className='pt-10'>
                                    {renderRating(testimonial.rating)}
                                </div>
                                <h3 className="text-lg font-semibold pt-5 text-gray-900">
                                    {testimonial.name}
                                </h3>
                                <p className="text-sm text-gray-500">{testimonial.company}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ReviewSection;
