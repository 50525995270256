import React, { useState } from "react";
import LandingSearchBar from "components/LandingSearchBar/LandingSearchBar";
import Travel from "components/Locations/Travel";

export default function LandingHeader() {
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmedSearchTerm, setConfirmedSearchTerm] = useState('');

  const handleSearch = () => {
    setConfirmedSearchTerm(searchTerm);
    console.log("Searching for:", searchTerm);
  };


  return (
    <div>
      <div className="relative pb-32 bg-center h-full">
        <div
          className="absolute top-0 w-full h-screen bg-center bg-cover bg-fixed"
          style={{
            backgroundImage: "url(" + require("assets/kkraimg/bg_pic.jpg") + ")",
          }}
        >
          <div className="absolute top-0 w-full h-full bg-black opacity-20" />
          <span
            id="blackOverlay"
            className="absolute w-full h-full bg-black opacity-10"
          ></span>
        </div>
      </div>
      <div className="relative z-10">
        <LandingSearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onSearch={handleSearch}
        />
      </div>
      <div className="block py-10 lg:pt-0 lg:mx-4 md:mx-14 mt-0 sm:mx-0 xs:mx-0">
        <Travel searchTerm={confirmedSearchTerm} />
      </div>
    </div>
  );
}
