import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BaseUrl } from '../BaseUrl';

const stripePromise = loadStripe('pk_test_51Q9liyRvLnAopkv6ijBD3Gn9cnlnZEGjZyP4JqMdiygwIwOdw9h8BinkU6IsRs84YpCDJmChZG7yrvN2EVnBvEzg005CUmuwfg');

const Payment = () => {
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const navigate = useNavigate();


    const { bookingData } = location.state || {};
    const { bookingId, price, title, image, description } = bookingData;
    const [clientSecret, setClientSecret] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (price) {
            fetch(`${BaseUrl}/api/create-payment-intent`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ bookingId, price }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.clientSecret) {
                        setClientSecret(data.clientSecret);
                    } else {
                        setErrorMessage('Error: clientSecret is missing in the response');
                    }
                })
                .catch(() => {
                    setErrorMessage('Error fetching client secret.');
                });
        }
    }, [bookingId, price]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) return;
        if (!clientSecret) {
            setErrorMessage('Client secret is missing.');
            setLoading(false);
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {},
            },
        });

        if (error) {
            setErrorMessage(error.message);
            setLoading(false);
        } else if (paymentIntent.status === 'requires_capture') {
            try {
               
                bookingData.paymentIntentId=paymentIntent.id

                console.log('paymentIntent', bookingData)
                const bookingResponse = await axios.post(`${BaseUrl}/api/bookings`,bookingData );

                if (bookingResponse.status === 201) {
                    toast.success('Payment and booking successful!');
                    // navigate('/booking-success');
                } else {
                    setErrorMessage('Booking was not successful, please contact support.');
                }
            } catch (bookingError) {
                setErrorMessage('Payment was successful but there was an error processing the booking.');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="max-w-6xl mx-auto p-6 pt-[100px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-white shadow-lg rounded-lg p-6">
                    <div className='flex items-center justify-between'>
                        <h2 className="text-2xl font-bold mb-4">{title}</h2>
                        <h2 className="text-2xl font-bold mb-4">${price}</h2>
                    </div>
                    <img src={image} alt={title} className="w-full h-64 object-cover rounded-lg mb-4" />
                    <p className="text-gray-600">{description}</p>
                </div>

                <div className="bg-white shadow-lg rounded-lg p-6">
                    {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <CardElement className="p-4 border rounded-lg" />
                        </div>
                        <button
                            type="submit"
                            className={`w-full bg-blue-600 text-white py-2 px-4 rounded-lg ${loading ? 'opacity-50' : ''}`}
                            disabled={!stripe || loading}
                        >
                            {loading ? 'Processing...' : `Pay ${price} USD`}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default function StripeContainer() {
    return (
        <Elements stripe={stripePromise}>
            <Payment />
        </Elements>
    );
}
