import React from "react";
import { FaStar, FaClock, FaBolt, FaWhatsapp } from "react-icons/fa";
import ReactWhatsapp from "react-whatsapp";

export default function HomeCard({ title, rating, days, nights, hours, price, image }) {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer">
      <img src={image} alt={title} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2 truncate">{title}</h3>
        <div className="flex justify-between">
          <div>
            <div className="">
              <div className="flex justify-start gap-x-2 items-center text-sm font-semibold text-gray-600 mb-2">
                <FaClock />
                <span> {days} Days ...</span>
              </div>
            </div>
            <div className="flex gap-x-2 items-center text-sm font-semibold text-orange-500">
              <FaBolt />
              <span> {price} AED</span>
            </div>
          </div>
          <div>
            <ReactWhatsapp
              number="+971551085136"
              message="Could you tell me about your tour Packages?"
              style={{
                backgroundColor: "#F0F0F0",
                color: "black",
                padding: "10px",
                display: "flex",
                alignItems: "center",
                zIndex: 1000,
                borderRadius: "8px",
                // boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              }}
            >
              <FaWhatsapp style={{ fontSize: "24px", marginRight: "10px" }} />
              WhatsApp
            </ReactWhatsapp>
          </div>
        </div>
      </div>
    </div>
  );
}
