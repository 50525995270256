import React from 'react';
import history from '../history';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import LandingPage from 'pages/Landingpage';
import ScrollToTop from 'components/ScrollToTop';
import AboutUs from 'components/AboutUs/AboutUs';
import ContactUs from 'components/ContactUs/ContactUs';
import Influencer from 'pages/influencer';
import TourDetail from '../components/TourDetails';
import Packages from 'components/Packages/Packages';
import SubscriptionPage from 'components/Subscription/SubscriptionPage';
import TravelBookingForm from 'components/AboutUs/TravelBookingForm';
import TravelDeals from 'components/DealCard';
import StripeContainer from 'components/Payment';
import FlightBookingForm from 'components/FlightBookingForm';
import LuxuryAccommodationForm from 'components/LuxuryAccommodationForm';
import TransportServicesForm from 'components/TransportServicesForm';
import GuidedToursForm from 'components/GuidedToursForm';
import RegisterForm from 'components/RegisterFrom';
import Navbar from 'components/NavBar/BnbNav';
import ServiceDetails from '../components/ServiceDetails';


function Root() {
  return (
    <Router history={history}>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/influencer" element={<Influencer />} />
        <Route path="/tour-detail" element={<TourDetail />} />
        <Route path="/travel-detail" element={<TravelBookingForm />} />
        <Route path="/travel-card" element={<TravelDeals />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path="/package" element={<Packages />} />
        <Route path="/subscription" element={<SubscriptionPage />} />
        <Route path="/bookflight" element={<FlightBookingForm />} />
        <Route path='/bookaccommodation' element={<LuxuryAccommodationForm />} />
        <Route path="/booktransport" element={<TransportServicesForm />} />
        <Route path='/booktour' element={<GuidedToursForm />} />
        <Route path="/payment" element={<StripeContainer />} />
        <Route path="/service-details/:id" element={<ServiceDetails />} />
      </Routes>
    </Router>
  );
}

export default Root;