import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import pic1 from '../../assets/kkraimg/abu-dhabi-sheikh.jpg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BaseUrl } from '../../BaseUrl';

const DestinationCard = ({ destination }) => (
  <div className="relative w-full h-80 rounded-lg overflow-hidden shadow-lg group">
    <img src={destination.image} alt={destination.name} className="w-full h-full object-cover" />

    <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-end pb-4 items-center">
      <h3 className="text-2xl font-bold text-white">{destination.name}</h3>
      <div className="flex mt-1">
        {[...Array(5)].map((_, i) => (
          <svg
            key={i}
            className={`w-4 h-4 ${i < destination.rating ? 'text-yellow-400' : 'text-yellow-400'}`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        ))}
      </div>
    </div>
  </div>
);

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: '#fff', color: 'black', borderRadius: '50%' }}
      onClick={onClick}
    >
      <ChevronRight className="w-6 h-6" />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: '#fff', color: 'black', borderRadius: '50%' }}
      onClick={onClick}
    >
      <ChevronLeft className="w-6 h-6" />
    </div>
  );
};

const Travel = ({ searchTerm }) => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await fetch(`${BaseUrl}/api/getAllLocations`);
        if (!response.ok) {
          throw new Error('Failed to fetch locations');
        }
        const data = await response.json();
        setLocations(data.locations);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLocations();
  }, []);

  const filteredLocations = searchTerm
    ? locations.filter(location =>
      location.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : locations;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="md:w-[95%] mx-auto p-4">
      <h2 className="text-3xl font-bold mb-2">Popular destinations</h2>
      <p className="text-gray-600 mb-6">
        Explore our curated selection of the world's most sought-after travel spots in this diverse list of must-visit places.
        From iconic cities to pristine natural wonders, we've gathered the best destinations to ignite your wanderlust.
      </p>
      <Slider {...settings}>
        {filteredLocations.map((destination) => (
          <div key={destination.id} className="p-2 ">
            <DestinationCard
              destination={{
                name: destination.name,
                image: destination.image
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Travel;
