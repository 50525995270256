import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Camera, MapPin, Users, Mail } from 'lucide-react';
import Footerbn from 'pages/Footer/Footerbn';
import desert from '../../assets/kkraimg/desert.jpg'
import angela from '../../assets/kkraimg/angela.png'
import TourismProgramCards from './TourismProgramCards';
import AboutUsSection from './AboutUsSection';
import TravelBookingForm from './TravelBookingForm';
import { BaseUrl } from '../../BaseUrl';
import axios from "axios";
const ServiceCard = ({  name, description, image }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <img src={image} alt={name} className="w-full h-48 object-cover" />
      <div className="p-6">
        <h3 className="text-xl font-semibold mt-2 mb-3">{name}</h3>
        <p className="text-gray-600 mb-4">
          {isExpanded ? description : `${description.slice(0, 200)}...`}
          {description.length > 120 && (
            <button
              className="text-blue-500 ml-2"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? 'View Less' : 'View More'}
            </button>
          )}
        </p>
      </div>
    </div>
  )
};
const AboutUs = () => {

  const [services, setServices] = useState([]);

  useEffect(() => {
    // Fetch services from the API when the component mounts
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/services`); // Update with the correct API endpoint
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);
  const [activeTab, setActiveTab] = useState('mission');

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };


  return (
    <>
      {/* <BnbNav /> */}
      <div className="bg-gradient-to-b from-blue-50 to-white min-h-screen font-sans flex items-center justify-center w-full">
        <main className="container    p-6">
          <motion.section
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.5 }}
            className="text-center mb-16"
          >
            <AboutUsSection />
          </motion.section>

          <div className="grid md:grid-cols-2 gap-12 mb-16">
            <motion.img
              src={desert}
              alt="Dubai Skyline"
              className="rounded-lg shadow-xl"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            />
            <div>
              <h3 className="text-3xl font-bold mb-6 ">Why Choose Us?</h3>
              {[
                {
                  icon: <Camera className="w-6 h-6 text-blue-500" />,
                  text: "Unforgettable Experiences",
                },
                {
                  icon: <MapPin className="w-6 h-6 text-blue-500" />,
                  text: "Local Expertise",
                },
                {
                  icon: <Users className="w-6 h-6 text-blue-500" />,
                  text: "Personalized Service",
                },
                {
                  icon: <Mail className="w-6 h-6 text-blue-500" />,
                  text: "24/7 Support",
                },
              ].map((item, index) => (
                <motion.div
                  key={index}
                  className="flex items-center mb-4"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  {item.icon}
                  <span className="ml-4 text-lg text-gray-700">
                    {item.text}
                  </span>
                </motion.div>
              ))}
            </div>
          </div>

          <section className="mb-16">
            <div className="flex justify-center mb-8">
              {["mission", "vision", "values"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-6 py-2 md:text-lg font-semibold rounded-full mx-2  transition duration-300 ${
                    activeTab === tab
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-lg shadow-xl p-8"
            >
              {activeTab === "mission" && (
                <ol className="list-decimal list-outside text-lg text-gray-700">
                  <li>
                    To provide unforgettable travel experiences that showcase
                    the natural beauty, cultural richness, and hospitality of
                    our country.
                  </li>
                  <li>
                    To create seamless, enjoyable, and safe journeys for
                    travelers, promoting sustainable tourism that respects both
                    local communities and the environment.
                  </li>
                  <li>
                    To be a trusted partner for tourists worldwide, offering
                    authentic and personalized experiences that foster lasting
                    memories and connections with our land.
                  </li>
                  <li>
                    To promote our country's heritage, landscapes, and
                    traditions, contributing to economic growth and cultural
                    preservation
                  </li>
                  <li>
                    To inspire people to explore and appreciate the diversity of
                    our destinations while promoting responsible and ethical
                    travel.
                  </li>
                </ol>
              )}
              {activeTab === "vision" && (
                <ol className="list-decimal list-outside text-lg text-gray-700">
                  <li>
                    To become a globally recognized leader in sustainable
                    tourism, known for our commitment to environmental
                    preservation, cultural integrity, and exceptional guest
                    experiences.
                  </li>
                  <li>
                    To position our country as a top-choice destination,
                    celebrated for its beauty, rich history, and vibrant
                    culture.
                  </li>
                  <li>
                    To build a future where travel is accessible, enriching, and
                    beneficial for both visitors and the communities they visit.
                  </li>
                  <li>
                    To be an industry pioneer, setting standards in hospitality,
                    safety, and sustainability for travel companies worldwide.
                  </li>
                  <li>
                    To create a world where people from all walks of life are
                    inspired to discover and connect with our unique
                    destinations, fostering global understanding and unity.
                  </li>
                </ol>
              )}
              {activeTab === "values" && (
                <ol className="list-decimal list-outside text-lg text-gray-700">
                  <li>
                    Hospitality – Embracing each guest with warmth, respect, and
                    exceptional service.
                  </li>
                  <li>
                    Sustainability – Committing to practices that protect our
                    environment, preserve cultural heritage, and promote
                    long-term benefits for local communities.
                  </li>
                  <li>
                    Integrity – Ensuring transparency, honesty, and ethical
                    practices in every aspect of our business.
                  </li>
                  <li>
                    Innovation – Continuously evolving to offer unique,
                    memorable experiences that resonate with modern travelers.
                  </li>
                  <li>
                    Cultural Appreciation – Celebrating and preserving the rich
                    traditions, art, and heritage of our country through every
                    travel experience.
                  </li>
                  <li>
                    Customer-Centricity – Putting our guests’ needs, safety, and
                    satisfaction at the heart of everything we do, creating
                    journeys that inspire and fulfill.
                  </li>
                </ol>
              )}
            </motion.div>
          </section>
          <TourismProgramCards />
          <div className="container py-8">
            <h2 className="text-3xl font-bold mb-16 text-center">Our Travel Services</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <ServiceCard
                  key={index}
                  name={service.name}
                  description={service.description}
                  image={service.image}
                />
              ))}
            </div>
          </div>

          <section className="mb-16">
            <h3 className="text-3xl font-bold mb-8 text-center ">Our Team</h3>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                { name: "Sarah Al-Maktoum", role: "Founder & CEO" },
                { name: "Ahmed Hassan", role: "Head Tour Guide" },
                { name: "Fatima Rashid", role: "Customer Experience Manager" },
              ].map((member, index) => (
                <motion.div
                  key={index}
                  className="bg-white rounded-lg shadow-xl p-6 text-center"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <img
                    src={angela}
                    alt={member.name}
                    className="rounded-full mx-auto mb-4"
                  />
                  <h4 className="text-xl font-bold mb-2">{member.name}</h4>
                  <p className="text-gray-600">{member.role}</p>
                </motion.div>
              ))}
            </div>
          </section>

          <TravelBookingForm />
        </main>
      </div>
      <Footerbn />
    </>
  );
};

export default AboutUs;
