import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Container from "@mui/material/Container";
import LandingHeader from "components/Headers/LandingHeader";
import HomeCard from "../../components/Cards/HomeCard";
import Navbar from "components/Navbars/AuthNavbar";
import Footerbn from "pages/Footer/Footerbn";
import TourDetail from "../../components/TourDetails";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "BaseUrl";
import ReviewSection from "components/Reviews/ReviewSection";
import Travel from "../../components/Locations/Travel";
import Influencers from "pages/influencer";
import Services from "pages/servicesweoffer/Services";

export default function LandingPage() {
  const navigate = useNavigate()
  const [selectedPackage, setSelectedPackage] = useState(null);
  console.log("img:", selectedPackage)

  const [packages, setPackages] = useState([]);
  console.log('pkg', packages)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState([]);

  const handleSelectPackage = (pkg) => {
    setSelectedPackage(pkg);
    navigate('/tour-detail', { state: { selectedPackage: pkg } });
  };
  const handleBack = () => {
    setSelectedPackage(null);
  };


  const settings = {
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 640, settings: { slidesToShow: 1 } },
    ],
  };

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/getpackages`);
        setPackages(response.data.packages);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch packages');
        setLoading(false);
      }
    };

    fetchPackages();
  }, [])

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/getalllocations`);
        setLocations(response.data.locations);
      } catch (err) {
        console.error("Error fetching locations:", err);
      }
    };

    fetchLocations();
  }, []);


  return (
    <>
      <Navbar />
      <main style={{ overflowX: 'hidden' }}>
        <LandingHeader />
        <section className="block lg:pt-0 lg:mx-12 sm:mx-0 xs:mx-0">
          <Container maxWidth="lg">
            <div className="rounded-xl">
              <div className="text-center">
                <h4 className="text-[#000000] text-3xl font-bold">
                  Packages
                </h4>
              </div>
              <div className="pt-10">
                {selectedPackage ? (
                  <div>
                    <button onClick={handleBack} className="mb-4 text-blue-600">← Back</button>
                    <TourDetail
                      title={selectedPackage.title}
                      rating={selectedPackage.rating}
                      days={selectedPackage.days}
                      nights={selectedPackage.nights}
                      hours={selectedPackage.hours}
                      price={selectedPackage.price}
                      image={selectedPackage.image}
                      description={selectedPackage.description}
                      date={selectedPackage.date}
                    />
                  </div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {packages && Array.isArray(packages) && packages.map((pkg, index) => (
                      <div key={index} onClick={() => handleSelectPackage(pkg)}>
                        <HomeCard
                          title={pkg.title}
                          rating={pkg.rating}
                          days={pkg.days}
                          nights={pkg.nights}
                          hours={pkg.hours}
                          price={pkg.price}
                          image={pkg.image}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Container>
        </section>
        <section className="block lg:pt-0 lg:mx-12 sm:mx-0 xs:mx-0">
          <Container maxWidth="lg">
            <Services />
          </Container>
        </section>
        <section className="">
          <section className="bg-[#58C0D0]">
            <section className="block py-10 mt-12 lg:pt-0">
              <Container maxWidth="lg">

                <div className="flex w-full pt-10 gap-4 md:gap-8 xs:flex-wrap sm:justify-center xs:justify-center sm:flex-wrap lg:flex-row">
                  <div className="bg-white w-full md:w-[300px] lg:w-[260px] z-10 py-10 rounded-xl">
                    <div className="flex flex-col items-center justify-center">
                      <div className="w-[60px] h-[60px]">
                        <img src={require("assets/img/freeValue.png")} />
                      </div>
                      <div className="text-center">
                        <h4 className="text-[#000000] pt-3 text-md font-normal">
                          Far More Value
                        </h4>
                        <p className="text-[#AFB5C1] pt-3 text-base font-normal w-auto mx-3">
                          Travels with exclusive deals, premium experiences, and unmatched support.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white w-full md:w-[300px] lg:w-[260px] z-10 py-10 rounded-xl">
                    <div className="flex flex-col items-center justify-center">
                      <div className="w-[60px] h-[60px]">
                        <img src={require("assets/img/accomodation.png")} />
                      </div>
                      <div className="text-center">
                        <h4 className="text-[#000000] pt-3 text-md font-normal">
                          Trusted Travelers

                        </h4>
                        <p className="text-[#AFB5C1] pt-3 text-base font-normal w-auto mx-3">
                          Responsible and trustworthy travelling agents.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white w-full md:w-[300px] lg:w-[260px] z-10 py-10 rounded-xl">
                    <div className="flex flex-col items-center justify-center">
                      <div className="w-[60px] h-[60px]">
                        <img src={require("assets/img/community-icon.png")} />
                      </div>

                      <div className="text-center">
                        <h4 className="text-[#000000] pt-3 text-md font-normal">
                          Exclusive Member Benefits
                        </h4>
                        <p className="text-[#AFB5C1] pt-3 text-base font-normal w-auto mx-3">
                          Special discounts, upgrades, and priority bookings with our exclusive membership program.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white w-full md:w-[300px] lg:w-[260px] z-10 py-10 rounded-xl">
                    <div className="flex flex-col items-center justify-center">
                      <div className="w-[60px] h-[60px]">
                        <img src={require("assets/img/guests.png")} />
                      </div>
                      <div className="text-center">
                        <h4 className="text-[#000000] pt-3 text-md font-normal">
                          Top-Rated Local Guides
                        </h4>
                        <p className="text-[#AFB5C1] pt-3 text-base font-normal w-auto mx-3">
                          Explore destinations with trusted, top-rated local guides.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full  relative max-w-[1200px] ml-auto mr-auto px-6 md:px-[120px] mt-10 z-40 hidden md:flex flex-wrap justify-between">
                  <div
                    className="w-[20px] rounded-full border border-[#FFFFFF] bg-[#58C0D0] h-[20px]"
                    style={{ zIndex: "40" }}
                  />
                  <div
                    className="w-[20px] rounded-full bg-[#C1E1C2] h-[20px]"
                    style={{ zIndex: "40" }}
                  />
                  <div
                    className="w-[20px] rounded-full bg-[#E8AD21] h-[20px]"
                    style={{ zIndex: "40" }}
                  />
                  <div
                    className="w-[20px] rounded-full bg-[#C0392D] h-[20px]"
                    style={{ zIndex: "40" }}
                  />
                  <Divider className="w-full absolute top-2.5 left-0 bg-[#EDEDED] max-w-[1140px] ml-auto mr-auto mt-[-10px] z-0" />
                </div>
              </Container>
            </section>
          </section>
        </section>

        <section className="block py-10 lg:pt-0 lg:mx-12 sm:mx-0 xs:mx-0">
          <Container maxWidth="lg">
            <div className="w-full mt-8">
              <div className="text-center">
                <h4 className="text-[#000000] text-3xl font-bold">
                  Trending Destinations
                </h4>
              </div>
              <div className="md:flex w-full h-full md:h-[600px] mt-5 ">
                <div className="relative w-full">
                  <img
                    className="h-[100%] w-full rounded-l-md object-cover"
                    src={require("assets/kkraimg/desert.jpg")}
                  />
                </div>
                <div className="w-full md:flex md:flex-col">
                  <div className="w-full md:flex">
                    <div className="relative w-full group">
                      <img
                        className="h-[300px] w-full object-cover transform transition-transform group-hover:scale-105"
                        src={require("assets/kkraimg/cruise.jpeg")}
                      />
                    </div>
                    <div className="relative w-full group">
                      <img
                        className="h-[300px] rounded-tr-md w-full object-cover transform transition-transform group-hover:scale-105"
                        src={require("assets/kkraimg/burjtop.jpeg")}
                      />
                    </div>
                  </div>
                  <div className="md:flex">
                    <div className="relative w-full group">
                      <img
                        className="h-[300px] w-full object-cover transform transition-transform group-hover:scale-105"
                        src={require("assets/kkraimg/landscape.jpg")}
                      />
                    </div>
                    <div className="relative w-full group">
                      <img
                        className="h-[300px] w-full rounded-br-md object-cover transform transition-transform group-hover:scale-105"
                        src={require("assets/kkraimg/dubai-frame.jpg")}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </Container>
        </section>
        <section className="block py-10 lg:pt-0 lg:mx-12 sm:mx-0 xs:mx-0">
          <Container maxWidth="lg">
            <Influencers />
          </Container>
        </section>
        {/* <TravelDeals /> */}
        <section>
          {/* <OurBlogs /> */}
          <ReviewSection />
        </section>
      </main>
      <div className="flex justify-center  w-full">
        <section className=" flex relative z-50 md:top-20  w-[94%]  lg:mb-10 ">
          <Container sx={{
            width: '100%',        // xl:w-full
            maxWidth: '100%',     // xl:w-full
            '@media (min-width: 1024px)': {
              width: '90%',       // lg:w-[90%]
              maxWidth: '90%',    // lg:w-[90%]
            },
          }} style={{ paddingLeft: "6px", paddingRight: "6px" }}>
            <div
              className="bg-[#AF3227]/95 md:h-[300px] h-full lg:w-[95%] xl:w-[96%] mx-auto rounded-2xl "
              style={{
                backgroundImage:
                  "url(" + require("assets/img/badgebg.png") + ")",
                backgroundRepeat: "no-repeat",
              }}
            >

              <Container>

                <div className="items-center w-full justify-between md:h-[300px] h-[500px] md:flex">

                  <div className="lg:pl-10">
                    <div className="md:w-[445px] flex items-center justify-center  w-full">
                      <p className="text-white text-[25px] text-center pt-3 md:pt-0 md:text-[36px] font-bold">
                        Get travel tips, offers and inspiration from KKRA
                      </p>
                    </div>
                    <div className="relative pt-4">
                      <input
                        type="text" placeholder="Email address"
                        className="md:w-[400px] lg:w-[495px] bg-transparent  pl-5 border text-white  placeholder-white border-white w-full h-[50px] rounded-2xl"
                      />
                      <button className="text-[#C0392D] absolute right-0 text-base font-medium bg-white rounded-2xl md:w-[150px] w-full h-[50px]">
                        Subscribe
                      </button>
                    </div>
                  </div>
                  <div>
                    <img src={require("assets/img/downrrow.png")} />
                  </div>
                  <div className="relative">
                    <img src={require("assets/img/squad2.png")} />
                    <img
                      src={require("assets/img/squad.png")}
                      className="absolute z-40 top-4"
                    />
                  </div>
                </div>
              </Container>
            </div>

          </Container>
        </section>
      </div>
      <Footerbn />
    </>
  );
}